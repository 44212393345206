import { Box } from '@mui/material'
import React from 'react'
import styled from '@emotion/styled'

export const BoxStyled = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  mt: '120px',
  padding: '0 24px',
  boxSizing: 'border-box',
  '@media (max-width: 750px)': {
    marginTop: '40px'
  }
}))

const ContainerStyled = ({ children }) => {
  return (
    <BoxStyled
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        mt: '120px',
        padding: '0 24px',
        boxSizing: 'border-box'
      }}>
      {children}
    </BoxStyled>
  )
}

export default ContainerStyled
