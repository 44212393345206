import React from 'react'
import { Card, CardButton, CardContent, CardImage, CardText, CardWrapper } from './MainCards.styled'
import needsAssessment from '../../../../assets/images/home/needsAssessment.svg'
import CustomLink from '../../../components/Link/Link'
import training from '../../../../assets/images/home/training.svg'
import equpment from '../../../../assets/images/home/equpment.svg'
const mainCards = [
  {
    title: 'DSA Needs Assessments',
    content: 'Customised DSA Needs Assessment all about you for individualised learning...',
    label: 'Book your Assessment',
    path: '/student-services-assessments',
    image: needsAssessment
  },
  {
    title: 'Equipment & Software',
    content: 'Ready to place your DSA equipment order, or upgrade. Click here to order...',
    label: 'Order your Equipment',
    path: '/student-services-equipment-software',
    image: equpment
  },
  {
    title: 'AT Training',
    content:
      'Our AT packages and 1-1 training are all about new ways to work, saving time, improved grades, and reducing stress....',
    label: 'Book your AT Training',
    path: '/student-services-at-training',
    image: training
  }
]

const MainCards = () => {
  return (
    <CardWrapper>
      {mainCards.map(card => (
        <Card key={card.title}>
          <CardContent>
            <CardText variant='title'>{card.title}</CardText>
            <CardText variant='p'>{card.content}</CardText>
            <CustomLink to={card.path}>
              <CardButton text={card.label} />
            </CustomLink>
          </CardContent>

          <CardImage>
            <img src={card.image} alt={card.title} />
          </CardImage>
        </Card>
      ))}
    </CardWrapper>
  )
}

export default MainCards
