import { Box, Typography, Stack } from '@mui/material'
import { styled } from '@mui/system'

export const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  marginBottom: '120px',
  '@media (max-width: 750px)': {
    marginBottom: '56px'
  }
}))

export const ContentWrapper = styled(Box)(({ theme }) => ({
  marginTop: '42px',
  marginBottom: '80px',
  '@media (max-width: 750px)': {
    marginTop: '18px',
    marginBottom:'40px'
  }
}))

export const Card = styled(Box)(({ theme }) => ({
  boxSizing: 'content-box',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '80px 12px 10px 12px',
  background: 'linear-gradient(178.17deg, #F4CD00 -102.06%, #183561 105.19%)',
  minHeight: '480px',
  borderRadius: '120px 0',
  width: '206px',
  '@media (max-width: 830px)': {
    marginTop: '18px',
    minWidth: '168px',
    minHeight: '432px'
  }
}))

export const CardText = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontFamily: 'Open Sans Regular',
  color: '#FFFFFF',
  '&.MuiTypography-title': {
    fontWeight: 700,
    fontSize: '30px',
    lineHeight: '35px'
  },
  '&.MuiTypography-p': {
    marginTop: 4,
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '24px'
  },

  ///
  '@media (max-width: 750px)': {
    '&.MuiTypography-title': {
      fontSize: '18px',
      lineHeight: '24px'
    },
    '&.MuiTypography-p': {
      fontSize: '14px',
      lineHeight: '24px'
    }
  }
}))
