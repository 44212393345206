import * as React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, ButtonStyled, LinkStyled, LogoWrapper, Wrapper } from './Navbar.styled'
import DrawerStyled from './Drawer'
import Drawer from '@mui/material/Drawer'

import Logo from '../../../assets/icons/navbar/Logo.svg'
import { navItemsWithoutDropDown } from '../../../app/constants/const'
import CustomLink from '../Link/Link'

import './navbar.css'
const drawerWidth = '100%'

const NavbarTest = props => {
  const { window } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [focusItem, setFocusItem] = React.useState(null)

  const handleDrawerToggle = () => {
    setMobileOpen(prevState => !prevState)
  }
  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <Wrapper>
      <AppBar>
        <IconButton
          aria-label='open drawer'
          edge='start'
          onClick={handleDrawerToggle}
          sx={{
            mr: 2,
            display: {
              md: 'none',
              position: 'absolute',
              right: 2
            },
            width: '40px',
            height: '36px',
            color: '#FFFFFF'
          }}>
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '36px'
          }}>
          <CustomLink to='/' tabIndex={0}>
            <LogoWrapper>
              <img src={Logo} alt='STUDYTECH' />
            </LogoWrapper>
          </CustomLink>
          <Box
            sx={{
              display: {
                xs: 'none',
                sm: 'none',
                md: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center'
              }
            }}>
            <ul className={'nav__list'}>
              <li className='nav__listitem'>
                <span
                  className='span_nav_item'
                  tabIndex='0'
                  onFocus={() => setFocusItem('services')}
                  style={{ pointerEvents: 'none' }}>
                  Student Services
                </span>
                <ul
                  className={
                    focusItem === 'services'
                      ? 'nav__listitemdrop_services_focus'
                      : 'nav__listitemdrop'
                  }
                  onClick={() => setFocusItem(null)}
                  onMouseLeave={() => setFocusItem(null)}>
                  <li>
                    <LinkStyled to='/student-services-assessments'>Assessments</LinkStyled>
                  </li>
                  <li>
                    <LinkStyled to='/student-services-equipment-software'>
                      Equipment and Software
                    </LinkStyled>
                  </li>
                  <li>
                    <LinkStyled to='/student-services-at-training'>AT Training</LinkStyled>
                  </li>
                </ul>
              </li>
              {/*  */}
            </ul>
            <ul className={'nav__list'}>
              <li className='nav__listitem'>
                <span
                  className='span_nav_item'
                  tabIndex='0'
                  onFocus={() => setFocusItem('faqs')}
                  style={{ pointerEvents: 'none' }}>
                  FAQs
                </span>
                <ul
                  className={
                    focusItem === 'faqs' ? 'nav__listitemdrop_faqs_focus' : 'nav__listitemdrop'
                  }
                  onClick={() => setFocusItem(null)}
                  onMouseLeave={() => setFocusItem(null)}>
                  <li>
                    <LinkStyled to='/frequently-asked-questions-assessments'>
                      Assessments
                    </LinkStyled>
                  </li>
                  <li>
                    <LinkStyled to='/frequently-asked-questions-at-training'>
                      AT Training
                    </LinkStyled>
                  </li>
                  <li>
                    <LinkStyled to='/frequently-asked-questions-equipment'>Equipment</LinkStyled>
                  </li>
                </ul>
              </li>
            </ul>
            {navItemsWithoutDropDown.map(item => (
              <LinkStyled key={item.title} to={item.path} onFocus={() => setFocusItem(item.title)}>
                <ButtonStyled>{item.title}</ButtonStyled>
              </LinkStyled>
            ))}
          </Box>
        </Box>
      </AppBar>
      <Drawer
        container={container}
        variant='temporary'
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true
        }}
        sx={{
          display: { xs: 'block', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
        }}>
        <DrawerStyled handleDrawerToggle={handleDrawerToggle} />
      </Drawer>
    </Wrapper>
  )
}

NavbarTest.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
}

export default NavbarTest
