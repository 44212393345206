import React, { useRef, useState } from 'react'
import { ButtonStyled, FormWrapper } from './StudentServicesAssessment.styled'
import { GeneralText } from '../../../components/General/Text'
import { Stack, Box } from '@mui/material'
import { useForm } from 'react-hook-form'
import { ControlledTextField } from '../../../components/inputs/ControlledTextField'
import { ControlledDatePicker } from '../../../components/inputs/ControlledDatePicker'
import {
  fundingBodyOptions,
} from '../../../../app/constants/const'
import { ControlledAutocomplete } from '../../../components/inputs/ControlledAutocomplete'
import emailjs from '@emailjs/browser'
import toast from 'react-hot-toast'
import ControlledUploadFileField from '../../../components/inputs/ControlledUploadFile';

const Form = () => {
  const [isLoading, setIsLoading] = useState(false)
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm()

  const form = useRef()
  const onSubmit = async (data, e) => {
    e.preventDefault()
    setIsLoading(true)
    await emailjs
      .sendForm(
        process.env.REACT_APP_YOUR_SERVICE_ID,
        process.env.REACT_APP_YOUR_TEMPLATE_ID_ASSESSMENT,
        form.current,
        process.env.REACT_APP_YOUR_PUBLIC_KEY
      )
      .then(
        result => {
          toast.success(
            'Thank you for submitting the form. We will come back to you within 1-2 working days to arrange your needs assessment appointment.'
          )
          setIsLoading(false)
          reset()
        },
        error => {
          console.log('error => ' + error.text)
          toast.error('Oops, Something went wrong, try again later!')
          setIsLoading(false)
        }
      )
  }
  return (
    <FormWrapper>
      <GeneralText variant='h2'>Request an Assessment Appointment</GeneralText>
      <form onSubmit={handleSubmit(onSubmit)} ref={form}>
        <Stack direction={'column'} spacing={3}>
          <ControlledTextField
            variant='standard'
            control={control}
            name='firstName'
            label='First name*'
            rules={{ required: true, minLength: 3 }}
            error={!!errors.firstName}
            helperText='First name is required and should have a minimum length of 3'
          />
          <ControlledTextField
            variant='standard'
            control={control}
            name='surname'
            label='Surname*'
            rules={{ required: true, minLength: 3 }}
            error={!!errors.surname}
            helperText='Surname is required and should have a minimum length of 3'
          />

          <ControlledTextField
            control={control}
            name='phone'
            label='Contact number*'
            type='number'
            variant='standard'
            rules={{
              required: true,
              pattern: {
                value: /((\+44(\s\(0\)\s|\s0\s|\s)?)|0)[0-9]\d{3}(\s)?\d{6}/g
              }
            }}
            error={!!errors.phone}
            helperText='Invalid phone number format. Correct format : 07123 456789'
          />
          <ControlledTextField
            variant='standard'
            control={control}
            name='email'
            label='Email address*'
            rules={{
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
              }
            }}
            error={!!errors.email}
            helperText='Invalid email address'
          />
          <ControlledTextField
            variant='standard'
            control={control}
            name='crn'
            label='DSA funding body customer reference number*'
            type='number'
            rules={{ required: true, minLength: 2 }}
            error={!!errors.crn}
            helperText='CRN is required and should have a minimum length of 2'
          />
          <ControlledAutocomplete
            control={control}
            name='fundingBody'
            label='Funding body*'
            options={fundingBodyOptions}
            rules={{ required: true }}
            error={!!errors.fundingBody}
            helperText='Funding body is required'
          />
          {/* <ControlledAutocomplete
            control={control}
            name='yearOfStudy'
            label='Year of study*'
            options={yearOfStudyOptions}
            rules={{ required: true }}
            error={!!errors.yearOfStudy}
            helperText='Year of study is required'
          />
          <ControlledTextField
            variant='standard'
            control={control}
            name='conditions'
            label='Conditions'
            multiline
            minRows={2}
          />
          <ControlledAutocomplete
            control={control}
            name='preferredContact'
            label='Preferred contact*'
            options={preferredContactOptions}
            rules={{ required: true }}
            error={!!errors.preferredContact}
            helperText='Preferred contact is required'
          /> */}
          <ControlledUploadFileField
            fullWidth
            control={control}
            name='my_file'
            label='Upload DSA1 (attachment)'
            inputProps={{
              accept: '.pdf'
            }}
            rules={{ required: true }}
            error={!!errors.my_file}
            helperText='Attachment is required'
          />
          <ControlledTextField
            variant='standard'
            control={control}
            name='additionalInfo'
            label='Additional info'
            multiline
            minRows={2}
          />
        </Stack>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonStyled
            variant='contained'
            type='submit'
            sx={{ marginTop: 4 }}
            disabled={isLoading}>
            Send Enquiry
          </ButtonStyled>
        </Box>
      </form>
    </FormWrapper>
  )
}

export default Form
