import { Box } from '@mui/material'
import React from 'react'

const Container = ({ children }) => {
  return (
    <Box sx={{ maxWidth: '864px', width: '100%', margin: '0 auto', padding: '0 24px', boxSizing:'border-box' }}>
      {children}
    </Box>
  )
}

export default Container
