import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)(({ theme,color }) => ({
  textDecoration: 'none',
  fontFamily:'Open Sans Bold',
  color
}));

const CustomLink = ({ children, to,  ...props }) => {
  return (
    <StyledLink to={ to } { ...props }>
      {children}
    </StyledLink>
  );
};

export default CustomLink;