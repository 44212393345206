import React from 'react'
import dsaStudents from '../../../assets/images/dsa-students/dsaStudents.png'
import Container from '../../components/General/Container'
import { ContentWrapper, ImgWrapper, Wrapper } from './DsaStudents.styled'
import { GeneralText } from '../../components/General/Text'
import AssessmentProcess from '../../../assets/images/student-services/AssessmentProcess.svg'
import AssessmentProcessMob from '../../../assets/images/student-services/AssessmentProcessMob.svg'
import { useCurrentScreenWidth } from '../../../hooks/screenSize/useCurrentSceenWidth'

const DsaStudents = () => {
  const [screenWidth] = useCurrentScreenWidth()
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>Disabled Students’ Allowance (DSA) How it works</GeneralText>
        <GeneralText variant='p'>
          DSA is a Grant awarded by the Government to support those with a recognised condition
          during their time in a Higher Education Institution (HEI).
        </GeneralText>

        <ContentWrapper>
          <ImgWrapper>
            <img src={dsaStudents} alt='DSA Students' />
          </ImgWrapper>
        </ContentWrapper>
        <GeneralText variant='h2'>DSA Process</GeneralText>

        <ContentWrapper>
          <ul>
            <li>
              <GeneralText variant='h4'> Apply</GeneralText>
              <GeneralText variant='p'>
                You apply directly to Student Finance for DSA funding.
              </GeneralText>
            </li>
            <li>
              <GeneralText variant='h4'> Approval</GeneralText>
              <GeneralText variant='p'>
                You will receive approval from Student Finance to have an Assessment. You can book
                this by calling/emailing or registering through our portal via the email invite we
                will send you.
              </GeneralText>
            </li>
            <li>
              <GeneralText variant='h4'> Assessment</GeneralText>
              <GeneralText variant='p'>
                After booking, you'll meet a Study Needs Assessor to discuss your support
                requirements. This is an informal meeting, either online or in person. Following
                this, the Assessor provides a report with recommendations for equipment, training,
                and support.
              </GeneralText>
            </li>
            <li>
              <GeneralText variant='h4'> Order</GeneralText>
              <GeneralText variant='p'>
                Once Student Finance approves, we will contact you to confirm your equipment and
                support and offer you the chance to upgrade the technology.
              </GeneralText>
            </li>
            <li>
              <GeneralText variant='h4'> Training</GeneralText>
              <GeneralText variant='p'>
                At the same time, we will book your training sessions. Training is essential as it
                helps you master the recommended technology vital to achieving the best outcome from
                your studies.
              </GeneralText>
            </li>
            <li>
              <GeneralText variant='h4'> Support</GeneralText>
              <GeneralText variant='p'>
                You have access to our technical support teams and assessors to ensure that your
                recommended technology is working as it should for the duration of your course.
              </GeneralText>
            </li>
          </ul>
        </ContentWrapper>
        <GeneralText variant='h2'>What is DSA? (Disabled Students’ Allowance)</GeneralText>
        <ContentWrapper>
          <GeneralText variant='p'>
            It provides support to help you thrive in higher education by overcoming obstacles you
            might encounter, whether they relate to your academic studies, physical accessibility,
            or overall well-being.
          </GeneralText>
        </ContentWrapper>
        <ContentWrapper>
          <GeneralText variant='p'>
            Although it’s called an ‘allowance’, you won’t be given any money directly. Instead,
            when you are awarded DSA, you will have the chance to arrange your support through
            specific providers (based on your location) who receive your funding directly from your
            student finance body.
          </GeneralText>
        </ContentWrapper>
        <ContentWrapper>
          <GeneralText variant='p'>
            If you have a disability, mental health condition, neurodiversity, chronic illness
            and/or learning difference, you may be able to access DSA. If you’re unsure if you might
            be eligible, check out the governments’{' '}
            <a
              href='https://www.gov.uk/disabled-students-allowance-dsa/eligibility'
              target='_blank'
              rel='noreferrer'
              className='email-link'>
              Eligibility
            </a>
            .
          </GeneralText>
        </ContentWrapper>
        <ContentWrapper>
          <GeneralText variant='p'>
            To apply for DSA, please follow this{' '}
            <a
              href='https://www.gov.uk/disabled-students-allowance-dsa/how-to-claim'
              target='_blank'
              rel='noreferrer'
              className='email-link'>
              link
            </a>
            .
          </GeneralText>
        </ContentWrapper>
      </Wrapper>
    </Container>
  )
}

export default DsaStudents
