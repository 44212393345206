import { Box, Typography, Button } from '@mui/material'
import { styled } from '@mui/system'

export const CardWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: '77px',
  '@media (max-width: 750px)': {
    marginBottom: '56px'
  }
}))

export const Card = styled(Box)(({ theme }) => ({
  width: '100%',
  borderRadius: '120px 0',
  backgroundColor: '#FFF',
  minHeight: '304px',
  maxHeight: '304px',
  height: '100%',
  marginTop: '80px',
  display: 'flex',
  justifyContent: 'space-between',
  '@media (max-width: 790px)': {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxHeight: '564px'
  },
  '@media (max-width: 370px)': {
    maxHeight: '100%'
  }
}))

export const CardContent = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '780px',
  padding: '80px 82px 32px 56px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '@media (max-width: 780px)': {
    padding: '56px 40px'
  }
}))

export const CardImage = styled(Box)(({ theme }) => ({
  maxWidth: '390px',
  height: '100%',
  '& img': {
    '@media (max-width: 790px)': {
      width: '100%'
    }
  }
}))

export const CardText = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontFamily: 'Open Sans Regular',
  color: '#183561;',
  '&.MuiTypography-title': {
    fontWeight: 600,
    fontSize: '32px',
    lineHeight: '30px'
  },
  '&.MuiTypography-p': {
    marginTop: 4,
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '30px'
  },

  ///
  '@media (max-width: 750px)': {
    '&.MuiTypography-title': {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: 700
    },
    '&.MuiTypography-p': {
      fontSize: '18px',
      lineHeight: '30px'
    }
  }
}))

export const CardButton = ({ text }) => {
  return (
    <Button
      variant='contained'
      sx={{
        maxWidth: '289px',
        width: '100%',
        height: '54px',
        backgroundColor: '#F4CD00',
        borderRadius: '31px',
        fontFamily: 'Open Sans Regular',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '26px',
        color: '#183561',
        textTransform: 'none',
        ':hover': { backgroundColor: '#F4CD00' },
        '@media (max-width: 750px)': {
          fontWeight: 500,
          fontSize: '15px',
          lineHeight: '24px',
          width: '100%',
          height: '44px',
          alignSelf: 'flex-end',
          marginTop: '12px'
        }
      }}>
      {text}
    </Button>
  )
}
