import React from 'react'
import Container from '../../../components/General/Container'
import { ContentWrapper, ImgWrapper, Wrapper } from './StudentServicesAssessment.styled'
import { GeneralText } from '../../../components/General/Text'
import AssessmentProcess from '../../../../assets/images/student-services/AssessmentProcess.svg'
import AssessmentProcessMob from '../../../../assets/images/student-services/AssessmentProcessMob.svg'
import { useCurrentScreenWidth } from '../../../../hooks/screenSize/useCurrentSceenWidth'
import Form from './Form'
import { Link } from 'react-router-dom'
const StudentServicesAssessment = () => {
  const [screenWidth] = useCurrentScreenWidth()
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>DSA Needs Assessments</GeneralText>
        <GeneralText variant='p'>
          Great news for students entitled to DSA, Study Tech has a team of helpful assessors with a
          wealth of experience ready to provide a tailored assessment. It is designed to help you
          excel in your academic journey.
        </GeneralText>
        <ContentWrapper>
          <GeneralText variant='p'>
            If you are a student funded by Student Finance England or Wales and received your DSA1
            Entitlement letter after the 26th of February 2024, we will contact you directly.
          </GeneralText>
        </ContentWrapper>
        <GeneralText variant='h2'>Who should fill in the form below:</GeneralText>
        <ContentWrapper>
          <GeneralText variant='p'>
            1. If you have not provided Consent to Share with your DSA supplier, we will not have
            your details, so please get in touch with us via the form below to arrange your Needs
            Assessment.
          </GeneralText>
        </ContentWrapper>
        <ContentWrapper>
          <GeneralText variant='p'>
            2. If you have received a DSA1 Entitlement letter dated before the 26th of February 2024
            and found our details on the gov.uk website, please also use the form below.
          </GeneralText>
        </ContentWrapper>

        {/* <GeneralText variant='h1'>Needs Assessment Process</GeneralText> */}
        {/* <ImgWrapper>
          <img
            src={screenWidth >= 550 ? AssessmentProcess : AssessmentProcessMob}
            alt='Needs Assessment Process'
          />
        </ImgWrapper> */}
        <Form />
        <ContentWrapper>
          <GeneralText variant='p'>
            If you have any queries about your Study Tech assessment, get in touch with us at 0204
            532 2084 and select Option 1 or by email{' '}
            <a href='mailto:assessments@study.tech' className='email-link'>
              assessments@study.tech
            </a>
          </GeneralText>
        </ContentWrapper>
      </Wrapper>
    </Container>
  )
}

export default StudentServicesAssessment
