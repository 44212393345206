import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@mui/material'
import React from 'react'
import { navItemsTest, navItemsWithoutDropDown } from '../../../app/constants/const'
import CustomLink from '../Link/Link'
import { LinkStyled } from './Navbar.styled'
import Logo from '../../../assets/icons/navbar/LogoDark.svg'
import CloseIcon from '@mui/icons-material/Close'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const DrawerStyled = ({ handleDrawerToggle }) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          width: '100%',
          height: '38px',
          paddingX: '16px',
          borderBottom: '1px solid rgba(16, 24, 40, 0.1)'
        }}>
        <CustomLink to='/' onClick={handleDrawerToggle}>
          <img src={Logo} alt='STUDYTECH' width={'150px'} />
        </CustomLink>
        <IconButton onClick={handleDrawerToggle}>
          <CloseIcon sx={{ color: '#183561' }} />
        </IconButton>
      </Box>

      {navItemsTest.map((item, index) => (
        <Accordion
          key={index}
          disableGutters
          sx={{
            boxShadow: 'none',
            '&:before': {
              display: 'none'
            },
            margin: 0,
            paddingY: 0
          }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ height: '51px', margin: 0, paddingY: 0 }}>
            <Typography
              sx={{
                color: '#183561',
                fontSize: '16px',
                fontFamily: 'Open Sans Regular',
                fontWeight: 600,
                padding: 0,
                margin: 0
              }}>
              {item.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <List
              sx={{
                color: '#183561',
                fontSize: '15px',
                fontFamily: 'Open Sans Regular',
                fontWeight: 400,
                margin: 0,
                padding: 0
              }}>
              {item.links.map((link, linkIndex) => (
                <LinkStyled key={link.title} to={link.path} onClick={handleDrawerToggle}>
                  <ListItem disablePadding>
                    <ListItemButton>{link.title}</ListItemButton>
                  </ListItem>
                </LinkStyled>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
      <List
        sx={{
          color: '#183561',
          fontSize: '15px',
          fontFamily: 'Open Sans Regular',
          fontWeight: 400,
          margin: 0,
          padding: 0
        }}>
        {navItemsWithoutDropDown.map((link, linkIndex) => (
          <LinkStyled to={link.path} onClick={handleDrawerToggle} key={link.title}>
            <ListItem disablePadding>
              <ListItemButton>{link.title}</ListItemButton>
            </ListItem>
          </LinkStyled>
        ))}
      </List>
    </Box>
  )
}

export default DrawerStyled
