import React from 'react'
import Container from '../../../components/General/Container'
import { Wrapper } from '../student-services-assessment/StudentServicesAssessment.styled'
import { GeneralText } from '../../../components/General/Text'
import { ContentWrapper } from './EquipmentSoftware.styled'
import EquipmentCards from './EquipmentCards'
import Form from './Form'

const EquipmentSoftware = () => {
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>Equipment & Software</GeneralText>

        <ContentWrapper>
          <GeneralText variant='p'>
            We provide a comprehensive range of assistive technologies to meet each individual's
            requirements. Approximately 50% of our customers upgrade their laptop from the standard
            recommended laptop. We will send you various options, and our friendly team is available
            to discuss the different computer specifications.
          </GeneralText>
        </ContentWrapper>
        <GeneralText variant='pSemibold'>
          Please know that you can only upgrade your computer before we ship your order. We cannot
          change the computer later in the DSA process.
        </GeneralText>
        <ContentWrapper>
          <ul>
            <li>
              <GeneralText variant='h4'> Place Your Order</GeneralText>
              <GeneralText variant='p'>
                Email{' '}
                <a href='mailto:orders@study.tech' className='email-link'>
                  orders@study.tech
                </a>{' '}
                or call us at 0204 532 2084 – Option 2.
              </GeneralText>
            </li>
            <li>
              <GeneralText variant='h4'> Delivery</GeneralText>
              <GeneralText variant='p'>
                Your order will be delivered within the time frame specified in your delivery
                confirmation. For bespoke upgrades, waiting times can be longer if manufacturers
                make your computer to order.
              </GeneralText>
            </li>
            <li>
              <GeneralText variant='h4'> Feedback</GeneralText>
              <GeneralText variant='p'>
                We would love to hear from you. We will contact you to collect feedback, which will
                allow us to improve our services constantly. Thank you for taking the time to
                respond 😊.
              </GeneralText>
            </li>
          </ul>
        </ContentWrapper>
        {/* <EquipmentCards /> */}
        {/* <Form /> */}
      </Wrapper>
    </Container>
  )
}

export default EquipmentSoftware
