import React from 'react'
import {
  Content,
  ImageWrapper,
  ThirdBlockWrapper,
  ThirdContentText,
  ThirdContentText2
} from './ThirdBlock.styled'
import EachSession from '../../../../../../assets/images/home/EachSession.png'
import { GeneralText } from '../../../../../components/General/Text'
import { Link } from 'react-router-dom'
const ThirdBlock = () => {
  return (
    <ThirdBlockWrapper>
      <Content>
        <GeneralText variant='h2'>Each session is bespoke to you</GeneralText>
        <ThirdContentText>
          <GeneralText variant='p'>
            Your sessions are tailored specifically to you, ensuring a personalised experience every
            time.
          </GeneralText>
        </ThirdContentText>
        <GeneralText variant='h2'>Flexibility</GeneralText>
        <ThirdContentText2>
          <GeneralText variant='p'>
            Life is unpredictable, and it's important to be able to adjust your plans accordingly.
            We understand, and our team are trained to be agile to meet your requirements.
          </GeneralText>
        </ThirdContentText2>
        <ThirdContentText2>
          <GeneralText variant='pSemibold'>
            By being flexible and open, we will achieve your academic goals and maintain a healthy
            work-life balance.
          </GeneralText>
          <br />
          <br />
          <Link to='/frequently-asked-questions-assessments' className='email-link'>
            FAQs Assessments
          </Link>
        </ThirdContentText2>
      </Content>
      <ImageWrapper>
        <img src={EachSession} alt='Each session is bespoke to you' />
      </ImageWrapper>
    </ThirdBlockWrapper>
  )
}

export default ThirdBlock
