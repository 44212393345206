import React from 'react'
import {
  Content,
  FirstBlockWrapper,
  FirstContentText,
  FirstContentText2,
  ImageWrapper
} from './FirstBlock.styled'
import SaveTime from '../../../../../../assets/images/home/SaveTime.png'
import { GeneralText } from '../../../../../components/General/Text';
const FirstBlock = () => {
  return (
    <FirstBlockWrapper>
      <Content>
        <GeneralText variant='h2'>Save time</GeneralText>
        <FirstContentText>
          <GeneralText variant='p' sx={{ mb: 12 }}>
            The burdens we face in academic and professional lives put pressure on all of us.
            Ensuring you are focused and fit is fundamental to completing commitments. Our
            experienced team are ready to help.
          </GeneralText>
        </FirstContentText>
        <FirstContentText2>
          <GeneralText variant='p' sx={{ mb: 6 }}>
            AT training will help you to enhance your time management, productivity, and academic
            performance.
          </GeneralText>
        </FirstContentText2>

        <GeneralText variant='p'>
          1-1 sessions are tailored to your individual requirements, enabling you to concentrate on
          areas where you need improvement. Trainers are focused on achieving higher marks and
          completing commitments.
        </GeneralText>
      </Content>
      <ImageWrapper>
        <img src={SaveTime} alt='Save Time' />
      </ImageWrapper>
    </FirstBlockWrapper>
  )
}

export default FirstBlock
