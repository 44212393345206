import MainRouter from './routes/MainRouter';
import './styles/App.css';
import { Helmet } from 'react-helmet-async';

function App() {
  const nonce =
    Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

  return (
    <>
      <Helmet>
        <script async src='https://www.googletagmanager.com/gtag/js?id=G-W9GRQN9Z68'></script>
        <script nonce={nonce}>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-W9GRQN9Z68');
          `}
        </script>
        <meta charSet='utf-8' />
        {/* Update CSP directive, adding nonce for styles */}
        <meta
          httpEquiv='Content-Security-Policy'
          content={`default-src 'self' 'unsafe-inline' 'unsafe-eval' https:; 
                    script-src 'self' https://www.googletagmanager.com/ 'nonce-${nonce}'; 
                    style-src  'self' 'unsafe-inline' 'unsafe-eval' https: data:;
                    img-src 'self' data:; 
                    connect-src 'self' https://www.google-analytics.com;`}></meta>
      </Helmet>
      <MainRouter />
    </>
  );
}

export default App;
