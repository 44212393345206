import { Box, Typography, styled } from '@mui/material'
export const MainWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '1230px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 750px)': {
    alignItems: 'center'
  }
}))
export const WrapperFooterMain = styled(Box)(({ theme }) => ({
  maxWidth: '1230px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '24px 0 120px 0',
}))

export const MainTitle = styled(Box)(({ theme }) => ({
  paddingLeft: '115px',
  maxWidth: '864px',
  width: '100%',
  '@media (max-width: 1205px)': {
    paddingLeft: '0'
  },
  '@media (max-width: 750px)': {
    maxWidth: '433px'
  }
}))

export const MainText = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  color: '#FFFFFF',
  '&.MuiTypography-h1': {
    fontFamily: 'Open Sans Bold',
    fontWeight: 700,
    fontSize: '45px',
    lineHeight: '52px'
  },

  '@media (max-width: 750px)': {
    '&.MuiTypography-h1': {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '25px'
    }
  }
}))
