import React from 'react'
import Container from '../../components/General/Container'
import { Wrapper } from './ContactUs.styled'
import { GeneralText } from '../../components/General/Text'
import { Box, Typography } from '@mui/material'

const ContactUs = () => {
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>Contact us</GeneralText>
        <GeneralText variant='h2'>Get in touch</GeneralText>
        <GeneralText variant='p'> Telephone: 0204 532 2084</GeneralText>
        <Box>
          <ul style={{ listStyle: 'none' }}>
            <li>
              <GeneralText variant='p'> Option 1 for Needs Assessments</GeneralText>
            </li>
            <li>
              <GeneralText variant='p'> Option 2 for Orders</GeneralText>
            </li>
            <li>
              <GeneralText variant='p'> Option 3 for AT Training</GeneralText>
            </li>
            <li>
              <GeneralText variant='p'> Option 4 for Technical Support</GeneralText>
            </li>
            <li>
              <GeneralText variant='p'> Option 5 for Ergonomics</GeneralText>
            </li>
          </ul>
        </Box>

        <GeneralText variant='p'>Email us at...</GeneralText>
        <Box>
          {' '}
          <a href='mailto:assessments@study.tech' className='email-link'>
            assessments@study.tech
          </a>
          <br />
          <a href='mailto:orders@study.tech' className='email-link'>
            orders@study.tech
          </a>
          <br />
          <a href='mailto:att@study.tech' className='email-link'>
            att@study.tech
          </a>
          <br />
          <a href='mailto:support@study.tech' className='email-link'>
            support@study.tech
          </a>
          <br />
          <a href='mailto:ergo@study.tech' className='email-link'>
            ergo@study.tech
          </a>
          <br />
          <br />
          <a href='mailto:media@study.tech' className='email-link'>
            media@study.tech
          </a>
          <br />
          <a href='mailto:opportunity@study.tech' className='email-link'>
            opportunity@study.tech
          </a>
        </Box>
      </Wrapper>
    </Container>
  )
}

export default ContactUs
