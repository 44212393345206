import React from 'react'
import Container from '../../components/General/Container'
import { MrWrapper, Wrapper } from './CookiePolicy.styled'
import { GeneralText } from '../../components/General/Text'

const CookiePolicy = () => {
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>Cookie Policy</GeneralText>
        <GeneralText variant='p'>
          We use a feature of your internet browser called a ‘cookie’ on the study.tech website. We
          do not use ‘cookies’ to retrieve personal information about you from your computer. We
          will only gain such information if you have knowingly and willingly provided such
          information to us.
        </GeneralText>

        <GeneralText variant='h2'>Your acknowledgement</GeneralText>
        <GeneralText variant='p'>
          We hope that after you have read our policy you will have sufficient details about the
          ‘cookies’ used on our websites to be reassured that we use them responsibly.By
          navigating the website, we will assume you are happy with ‘cookies’ being set by us to
          improve your experience. Cookies enable us and selected suppliers to show you products
          that we believe you will be interested in.
        </GeneralText>
        <GeneralText variant='h2'>What is a cookie?</GeneralText>

        <MrWrapper>
          <GeneralText variant='p'>
            A ‘cookie’ is a very small piece of information in the form of a text file placed on
            your computer hard drive by websites that you visit. ‘Cookies’ allow us to help you get
            the best out of your visit to the website and improve your user experience, for
            instance, by remembering that you have visited before. They can also be used to make
            sure that the content you see is relevant to you.
          </GeneralText>
        </MrWrapper>
        <GeneralText variant='p'>
          For further information about ‘cookies’ please visit http://www.allaboutcookies.org/ . In
          this policy, we have used the following categories of ‘cookies’
        </GeneralText>

        <MrWrapper>
          <GeneralText variant='p'>
            Strictly necessary <br /> Performance <br />
            Functionality
            <br />
            Targeting
          </GeneralText>
        </MrWrapper>
        <MrWrapper>
          <GeneralText variant='p'>
            This categorisation has been defined by The International Chamber Of Commerce (UK).
          </GeneralText>
        </MrWrapper>

        <GeneralText variant='h2'>How does StudyTech use cookies?</GeneralText>
        <GeneralText variant='p'>
          Our ‘cookies’ are used to store basic information, like your user identification, to
          improve the usability of the website, and to enable the accessibility features within our
          websites. They do not store passwords or credit card information.
        </GeneralText>

        <GeneralText variant='h2'>Strictly necessary cookies</GeneralText>
        <GeneralText variant='p'>
          ‘Cookies’ are set to help support the structure of the pages that are displayed to you.
          These help the website to function and enhance the look and feel of the website. They also
          help to improve navigation around our website and allow you to return to pages you have
          previously visited. This type of ‘cookie’ only lasts for the duration of the time you are
          visiting the website. When you leave the website, they are deleted automatically.
        </GeneralText>

        <GeneralText variant='h2'>Performance cookies</GeneralText>
        <GeneralText variant='p'>
          Cookies are used to provide aggregated statistics on visitors to our websites and browsing
          behaviour. This enables us to review the site flow to make customers’ journeys easier and
          if necessary, make other improvements. The data is aggregated and anonymised, which means
          we cannot identify you as an individual. We may use a number of tools to carry out
          analytical functions on our site, including Google Analytics. You can view their privacy
          policy here: http://www.google.com/analytics/learn/privacy.html
        </GeneralText>

        <GeneralText variant='h2'>Functionality cookies</GeneralText>
        <GeneralText variant='p'>
          We use cookies to make accessing the website more convenient, so you don’t need to
          remember your username/email address. This information will allow us to welcome you by
          name. For security reasons, you will still need to enter your password.
        </GeneralText>

        <MrWrapper>
          <GeneralText variant='p'>
            Our cookie will remain on your hard drive until you choose to clear it. If you choose to
            do this, you will need to enter your details each time you visit the site. It is
            possible for you to view our website without providing any personal information, but you
            will need to register your details so we can process any online order you may make. An
            advantage of registering is that we will be able to contact you about special offers
            from which you can benefit.
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='p'>
            Should you wish to disable ‘cookies’ from your web browser, please see Cookie
            maintenance – below.
          </GeneralText>
        </MrWrapper>

        <GeneralText variant='h2'>Cookie maintenance</GeneralText>
        <GeneralText variant='p'>
          You can set your browser to accept or reject all specific ‘cookies’. You can also set your
          browser to alert you each time a ‘cookie’ is presented to your computer. You can delete
          ‘cookies’ that have been stored on your computer, but remember, if you prevent us from
          placing ‘cookies’ on your computer during your visit or you subsequently delete a ‘cookie’
          that has been placed, it will not be possible for you to use our website effectively.
        </GeneralText>

        <MrWrapper>
          <GeneralText variant='p'>
            By disabling your ‘cookies’ from the browser settings, you will be restricting the
            ‘cookies’ that StudyTech uses to manage its website, and this will have an impact on how
            the website will function. This will apply to all websites once ‘cookies’ are disabled
            via your browser settings and not only StudyTech website.
          </GeneralText>
        </MrWrapper>

        <MrWrapper>
          <GeneralText variant='p'>
            If you would like to restrict or block ‘cookies’ that are set by us and other websites,
            you can do this through your web browser settings. ow. For other web browsers, please
            use the Help function on your browser for details on how to do this. For information on
            how to restrict or block ‘cookies’ on your mobile phone, you will need to refer to your
            handset manual.
          </GeneralText>
        </MrWrapper>
      </Wrapper>
    </Container>
  )
}

export default CookiePolicy
