export const navItems = [
  { title: 'About us', path: '/about' },
  { title: 'Press Release', path: '/press-release' },
  { title: 'FAQs', path: '/frequently-asked-questions' },
  { title: 'DSA Information', path: '/dsa-information' },
  { title: 'Careers', path: '/careers' },
  { title: 'Contact us', path: '/contact' }
]

export const navItemsTest = [
  // {
  //   title: 'About us',
  //   links: [
  //     { title: 'About us', path: '/about' },
  //     { title: 'Our Mission', path: '/mission' }
  //   ]
  // },
  {
    title: 'Student Services',
    links: [
      { title: 'Assessments', path: '/student-services-assessments' },
      { title: 'Equipment and Software', path: '/student-services-equipment-software' },
      { title: 'AT Training', path: '/student-services-at-training' }
    ]
  },
  // {
  //   title: 'DSA Information',
  //   links: [
  //     { title: 'DSA Information', path: '/dsa-information' },
  //     { title: 'What are the DSA?', path: '/what-are-the-dsa' },
  //     { title: 'DSA Eligibility', path: '/dsa-eligibility' },
  //     { title: 'Student Loans Company (SLC)', path: '/student-loans-company' }
  //   ]
  // },
  // {
  //   title: 'Support',
  //   links: [{ title: 'HEP / University Information', path: '/hep-university-information' }]
  // },
  {
    title: 'FAQs',
    links: [
      { title: 'Assessments', path: '/frequently-asked-questions-assessments' },
      { title: 'AT Training', path: '/frequently-asked-questions-at-training' },
      { title: 'Equipment', path: '/frequently-asked-questions-equipment' }
    ]
  }
]

export const navItemsWithoutDropDown = [
  // { title: 'BUZZ', path: '/buzz' },

  { title: 'About us', path: '/about' },
  // { title: 'Press Release', path: '/press-release' },
  // { title: 'FAQs', path: '/frequently-asked-questions' },
  { title: 'DSA Information', path: '/dsa-Information' },
  { title: 'Careers', path: '/careers' },
  { title: 'Contact us', path: '/contact' }
]

export const fundingBodyOptions = [
  {
    label: 'SFE - Student Finance England',
    value: 'SFE - Student Finance England'
  },
  {
    label: 'SAAS - Student Awards Agency Scotland',
    value: 'SAAS - Student Awards Agency Scotland'
  },
  {
    label: 'SFW - Student Finance Wales',
    value: 'SFW - Student Finance Wales'
  }
]

export const yearOfStudyOptions = [
  {
    label: '1 Year',
    value: '1 Year'
  },
  {
    label: '2 Year',
    value: '2 Year'
  },
  {
    label: '3 Year',
    value: '3 Year'
  },
  {
    label: '4 Year',
    value: '4 Year'
  },
  {
    label: '5 Year',
    value: '5 Year'
  },
  {
    label: '6 Years or longer',
    value: '6 Years or longer'
  }
]

export const preferredContactOptions = [
  {
    label: 'Email',
    value: 'Email'
  },
  {
    label: 'Phone',
    value: 'Phone'
  }
]

export const EMAILJS_API = 'https://api.emailjs.com/api/v1.0/email/send'
