import React from 'react'
import { Box } from '@mui/material'
import AppBar from './AppBar/AppBar'
import ContainerStyled from './Container'
import { MainWrapper } from './Main.styled'
import MainCards from './MainCards/MainCards'

const Main = () => {
  return (
    <Box>
      <AppBar />
      <ContainerStyled>
        <MainWrapper>
          <MainCards />
        </MainWrapper>
      </ContainerStyled>
    </Box>
  )
}

export default Main
