import React from 'react'
import { Content, ImageWrapper, SecondBlockWrapper, SecondContentText } from './SecondBlock.styled'
import FocusOnProductivity from '../../../../../../assets/images/home/FocusOnProductivity 1.png'
import { GeneralText } from '../../../../../components/General/Text';
const SecondBlock = () => {
  return (
    <SecondBlockWrapper>
      <ImageWrapper>
        <img src={FocusOnProductivity} alt='Focus on productivity ' />
      </ImageWrapper>
      <Content>
        <GeneralText variant='h2'>Focus on productivity</GeneralText>
        <SecondContentText>
          <GeneralText variant='p'>
            Increasing productivity doesn't mean working harder, it means working smarter.
          </GeneralText>
        </SecondContentText>
        <GeneralText variant='pSemibold'>
          “I looked at new ways of working with the AT Trainer. Some strategies we looked at weren’t
          for me, but others really were. The ones I liked, we went into more detail, and to be
          honest, I have completely changed the way I write essays now, saving me days of work on
          how I used to do things.”
        </GeneralText>
      </Content>
    </SecondBlockWrapper>
  )
}

export default SecondBlock
