import React from 'react'
import Container from '../../components/General/Container'
import { BoxStyled, ButtonWrapper, ContentWrapper, ImgWrapper, Wrapper } from './Careers.styled'
import { GeneralText } from '../../components/General/Text'
import careers from '../../../assets/images/careers/careers.png'
import GeneralButton from '../../components/General/GeneralButton'
import { Box } from '@mui/material'

const Careers = () => {
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>Careers</GeneralText>
        <GeneralText variant='pBold'>
          Join us in our mission to create a more inclusive world through technology. At StudyTech,
          we strive to attract the most talented individuals to help us accomplish this goal.
        </GeneralText>
        <BoxStyled>
          <ImgWrapper>
            <img src={careers} alt={'Careers'} />
          </ImgWrapper>

          <ButtonWrapper>
            <a href='mailto:opportunity@study.tech'>
              <GeneralButton text={'Our Job openings'} />
            </a>
          </ButtonWrapper>
        </BoxStyled>
        <GeneralText variant='h3'>
          Assistive Technology Trainers (Freelance and Full-Time)
        </GeneralText>
        <GeneralText variant='p'>
          Join our friendly team at StudyTech, a top provider of assistive technology training. We
          are currently on the lookout for skilled and committed AT trainers who can help shape the
          exciting new era of assistive technology training brought about by the changes in the
          Disabled Students' Allowance (DSA) landscape this year.
        </GeneralText>
        <ContentWrapper>
          <GeneralText variant='p'>
            If you have relevant experience as a DSA Assistive Technology Trainer, a personable and
            approachable teaching style, and the ability to adapt training to specific disabilities
            and learning needs, we want to hear from you. Whether you prefer freelance or full-time
            employment, simply send us your CV and express your interest in joining our team.
          </GeneralText>
        </ContentWrapper>
        <ContentWrapper>
          <GeneralText variant='p'>
            We are excited to recruit in the coming weeks and work together to empower learners and
            create a more inclusive world through technology. Reach out to us at{' '}
            <a href='mailto:opportunity@study.tech' className='email-link'>
              opportunity@study.tech
            </a>
          </GeneralText>
        </ContentWrapper>
      </Wrapper>
    </Container>
  )
}

export default Careers
