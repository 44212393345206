import React from 'react'
import Container from '../../../components/General/Container'
import { ContentWrapper, FagsText, Wrapper } from '../Fags.styled'
import { GeneralText } from '../../../components/General/Text'
import AccordionStyled from '../Accordion'
import { Box } from '@mui/material'

const assessmentFags = [
  {
    title: 'What is a needs assessment?',
    content: `A “DSA needs assessment” is a meeting with an experienced, friendly assessor who will discuss your individual condition and how it impacts your studies. The assessor will listen and then make tailored recommendations to fund the best possible support. The Student Loans Company will then check and approve the assessor's recommendations.`
  },
  {
    title: 'Why do I need to have a needs assessment?',
    content: `You must have a needs assessment to identify the equipment and support you need to study your course. Your Funding Body (Student Finance England or Wales) will require you to undergo this assessment to determine the necessary equipment and support. Additionally, the assessment will identify training that may be necessary to use the recommended equipment. It will also make recommendations for specific support that you may make use of during your 
    course, such as any special examination arrangements, one-to-one support, or other localised support requirements.
    If you require assistance with travel expenses for the assessment, you can ask your Funding Body if they can cover it from the General Allowances of the DSAs.`
  },
  {
    title: 'How soon can I have a needs assessment?',
    content: `To get the Disabled Students' Allowance (DSAs), you must confirm your eligibility with your funding body. Once approved, they'll send you a DSA1 letter with instructions on how to book your assessment. You can book your assessment before starting your course, but you must show evidence of your eligibility before booking an assessment with Study Tech.`
  },
  {
    title: 'How can I arrange my needs assessment?',
    content: `You do this by calling/emailing Study Tech or registering through our portal via the invite we will send you.

    You can contact Study Tech by emailing assessments@study.tech or by telephone on 0204 532 2084, option 1. After obtaining information about your course and disability, we will work together to schedule a suitable date, time, and location for your assessment. Once booked, we will send you an email confirmation of your assessment appointment, along with a map and directions to help you find us (if it is in person) or instructions on remote access via the platform of your choice (Teams, Zoom, Google Meet).`
  },

  {
    title: 'What evidence do I need to provide? ',
    content: `To apply for disability support, you must provide an eligibility letter from your funding body, such as Student Finance England or NHS. Along with this, you must also submit medical evidence or proof of your disability. A photocopy or photo of this evidence is sufficient for submission. Acceptable medical evidence includes a letter from your doctor or consultant or an educational psychologist's report.`
  },
  {
    title: 'What happens during the needs assessment?',
    content:
      'We respect your privacy throughout the assessment process. Our assessors are experienced in understanding disabilities and specific learning difficulties. The assessment allows you to discuss your learning requirements and challenges in the context of your studies. The assessor will consider all necessary technology and equipment you may need, along with support strategies such as note-taking, study skills tuition, and mentoring.'
  },

  {
    title: 'How long does a needs assessment take?',
    content:
      'Assessments are usually completed during the booking time; the assessor will ensure you have sufficient time to assess your needs and can schedule more time if required.'
  },

  {
    title: 'What do I need to bring with me to the needs assessment?',
    content:
      'Anything that will help us understand your needs. Examples include work you have created, such as lecture notes, assignments, a course outline, and your timetable.'
  },
  {
    title: 'What type of needs assessment is right for me?',
    content:
      `Your needs assessment can be completed in person or online by video call. We’ll be able to accommodate whichever option you prefer. <br/>
      Benefits of in-person assessments.<br/>
      It may suit you to attend your needs assessment in person if you:
      <ul>
        <li>would benefit from a physical demonstration of equipment and software that may be recommended</li>
        <li>have poor network/broadband coverage</li>
        <li>would like a session in a private place, free from distractions</li>
      </ul>
      Benefits of online video call assessments.<br/>
      It can be good to attend your needs assessment online if you:
      <ul>
        <li>are anxious about travelling</li>
        <li>feel more comfortable in a place you choose</li>
        <li>struggle to talk about your disability in person</li>
      </ul>
      If you’re not able to use online video calls, you can choose to have your assessment by telephone instead.
      `
  },
  {
    title: 'How long do we hold your data?',
    content: `We hold your personal information, course information, disability information, and medical evidence on our secure in-house database. We will keep this information for as long as we have a justifiable reason to do so. For instance, we may keep it for your course or a maximum of 6 years. - General Data Protection Regulation (GDPR)
      Get in touch 0204 532 2084 – Option 1
      assessments@study.tech
      `
  }
]

const AssessmentFaqs = () => {
  return (
    <Container>
      <Wrapper>
        <GeneralText variant='h1'>FAQs DSA Needs Assessments</GeneralText>
        <ContentWrapper>
          <GeneralText variant='p'>
            Are you wondering what to expect during a DSA Needs Assessment? This process aims to
            identify any barriers you may face in your studies due to a disability or specific
            learning difficulty. The assessment will help determine the support you need to achieve
            academic success.
          </GeneralText>
        </ContentWrapper>
        {assessmentFags.map(item => (
          <AccordionStyled key={item.title} fag={item} />
        ))}
        <ContentWrapper>
          <FagsText variant='contact'>Get in touch 0204 532 2084 - Option 1 </FagsText>
          <br />
          <br />
          <FagsText variant='contact'>
            Email us at{' '}
            <a href='mailto:assessments@study.tech' className='email-link'>
              assessments@study.tech
            </a>
          </FagsText>
        </ContentWrapper>
      </Wrapper>
    </Container>
  )
}

export default AssessmentFaqs
