import { Box, Typography } from '@mui/material'
import { styled } from '@mui/system'

export const AppBarWrapper = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  minHeight: '663px',
  position: 'relative',
  '& img': {
    width: '100%',
  },
  '@media (max-width: 1445px)': {
    '& img': {
      overflow: 'hidden',
      width: 'auto'
    }
  }
}))

export const AppBarContentWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '500px',
  left: '66%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '556px',
  width: '481px',
  maxHeight: '358px',
  height: '100%',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  flexDirection: 'column',
  background: 'rgba(255, 255, 255, 0.50)',
  borderRadius: '30px',
  padding: '24px 55px '
}))

export const AppBarText = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  color: '#FFFFFF',
  '&.MuiTypography-h1': {
    fontFamily: 'Open Sans Bold',
    fontWeight: 700,
    fontSize: '45px',
    lineHeight: '52px',
    color: '#183561'
  },
  '&.MuiTypography-p': {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '30px',
    fontFamily: 'Open Sans Bold',
    color: '#183561'
  },
  '@media (max-width: 750px)': {
    '&.MuiTypography-h1': {
      fontWeight: 700,
      fontSize: '25px',
      lineHeight: '34px',
    },
    '&.MuiTypography-p': {
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '20px',
    }
  }
}))

export const WrapperMobile = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: '#FFFFFF',
  '& img': {
    width: '100%'
  }
}))

export const ContentWrapperMobile = styled(Box)(({ theme }) => ({
  maxWidth: '308px',
  width: '100%',
  height: '200px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '36px 0 40px 0',
  textAlign: 'center',
  '@media (max-width: 900px)': {
    height: '100%'
  }
}))

export const MobileWafe = styled(Box)(({ theme }) => ({
  width: '100%',
  '& img': {
    width: '100%',
    height: '36px'
  },
  '@media (max-width: 750px)': {
    '& img': {
      position: 'absolute',
      bottom: '-12px'
    }
  }
}))
