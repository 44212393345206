import { Button } from '@mui/material'
import React from 'react'

const GeneralButton = ({ text }) => {
  return (
    <Button
      variant='contained'
      sx={{
        maxWidth:'236px',
        width: '100%',
        height: '54px',
        backgroundColor: '#F4CD00',
        borderRadius: '31px',
        fontFamily: 'Open Sans Regular',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '26px',
        color: '#183561',
        textTransform: 'none',
        ':hover': { backgroundColor: '#F4CD00' },
        '@media (max-width: 750px)': {
          fontWeight: 500,
          fontSize: '15px',
          lineHeight: '24px',
          width: '168px',
          height: '32px'
        }
      }}>
      {text}
    </Button>
  )
}

export default GeneralButton
