import React from 'react'
import {
  DividerStyled,
  FooterContainerStyled,
  FooterContentWrapperStyled,
  FooterLogo,
  LinksWrapper,
  ListItemsWrapperStyled,
  Text,
  Wrapper
} from './Footer.styled'
import footer_logo from '../../../assets/icons/navbar/Logo.svg'
import certificate from '../../../assets/icons/footer/certificate.svg'
import CustomLink from '../Link/Link'
import { Box } from '@mui/material'

const Footer = () => {
  return (
    <Wrapper component='footer'>
      <DividerStyled />

      <FooterContainerStyled maxWidth='1440px'>
        <FooterContentWrapperStyled>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CustomLink to='/'>
              <FooterLogo>
                <img src={footer_logo} alt='Study Tech' />
              </FooterLogo>
            </CustomLink>
            <FooterLogo sx={{ mt: 2 }}>
              <img src={certificate} alt='Study Tech' />
            </FooterLogo>
          </Box>
          <ListItemsWrapperStyled>
            <LinksWrapper>
              <CustomLink to='/cookie-policy'>
                <Text variant='p'>Cookie Policy</Text>
              </CustomLink>
              <CustomLink to='/accessibility'>
                <Text variant='p'>Accessibility</Text>
              </CustomLink>
              <CustomLink to='/modern-slavery-act'>
                <Text variant='p'>Modern Slavery Act</Text>
              </CustomLink>
            </LinksWrapper>
            <LinksWrapper>
              <CustomLink to='/complaints-policy'>
                <Text variant='p'>Complaints</Text>
              </CustomLink>
              <CustomLink to='/privacy-policy'>
                <Text variant='p'>Privacy Policy</Text>
              </CustomLink>
            </LinksWrapper>
          </ListItemsWrapperStyled>
        </FooterContentWrapperStyled>
      </FooterContainerStyled>
    </Wrapper>
  )
}

export default Footer
