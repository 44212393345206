import { Box } from '@mui/material'
import styled from '@emotion/styled'

export const ThirdBlockWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '1100px',
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '110px',
  paddingLeft: '115px',
  '@media (max-width: 1205px)': {
    paddingLeft: '0'
  },
  '@media (max-width: 750px)': {
    marginTop: '40px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start'
  }
}))

export const Content = styled(Box)(({ theme }) => ({
  maxWidth: '433px',
  height: '570px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  '@media (max-width: 750px)': {
    height: '100%'
  }
}))

export const ImageWrapper = styled(Box)(({ theme }) => ({
  maxWidth: '750px',
  width: '100%',
  height: '500px',
  '& img': {
    width: '100%'
  },
  '@media (max-width: 750px)': {
    height: '218px',
    display: 'flex',
    justifyContent: 'center'
  },
  '@media (max-width: 235px)': {
    display: 'none'
  }
}))

export const ThirdContentText = styled(Box)(({ theme }) => ({
  marginBottom: '40px'
}))

export const ThirdContentText2 = styled(Box)(({ theme }) => ({
  marginBottom: '24px',
  '@media (max-width: 750px)': {
    marginBottom: '20px'
  }
}))
