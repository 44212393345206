import React from 'react'
import attraining from '../../../../../assets/images/at-training/attraining.svg'
import attraining_mob from '../../../../../assets/images/at-training/attraining_mob.svg'
import {
  AppBarContentWrapper,
  AppBarText,
  AppBarWrapper,
  ContentWrapperMobile,
  MobileWafe,
  WrapperMobile
} from './AppBar.styled'
import { Box } from '@mui/material'
import GeneralButton from '../../../../components/General/GeneralButton'
import { useCurrentScreenWidth } from '../../../../../hooks/screenSize/useCurrentSceenWidth'
import wafe from '../../../../../assets/images/home/wafe.png'

const AppBar = () => {
  const [screenWidth] = useCurrentScreenWidth()
  return screenWidth <= 900 ? (
    <WrapperMobile>
      <img src={attraining_mob} alt='Study Tech' />
      <ContentWrapperMobile>
        <Box sx={{ marginBottom: '24px' }}>
          <AppBarText variant='h1'>AT Training</AppBarText>
        </Box>
        <Box sx={{ marginBottom: '24px' }}>
          <AppBarText variant='p'>
            Our goal is to provide you with personalised learning experiences that suit your needs.
            We're dedicated to helping you maximize the benefits of your Assistive Technology.
          </AppBarText>
        </Box>
        {/* <Box>
          <a href='#form'>
            <GeneralButton text={'Book your session'} />
          </a>
        </Box> */}
      </ContentWrapperMobile>
      <MobileWafe sx={{ backgroundColor: '#183561' }}>
        <img src={wafe} alt={'Study Tech'} />
      </MobileWafe>
    </WrapperMobile>
  ) : (
    <>
      <AppBarWrapper>
        <img src={attraining} alt='Study Tech' />
      </AppBarWrapper>
      <AppBarContentWrapper>
        <Box sx={{ alignSelf: 'flex-end' }}>
          <AppBarText variant='h1'>AT Training</AppBarText>
        </Box>
        <Box>
          <AppBarText variant='p'>
            Our goal is to provide you with personalised learning experiences that suit your needs.
            We're dedicated to helping you maximize the benefits of your Assistive Technology.
          </AppBarText>
        </Box>
        {/* <a href='#form' style={{ alignSelf: 'flex-end' }}>
          <GeneralButton text={'Book your session'} />
        </a> */}
      </AppBarContentWrapper>
    </>
  )
}

export default AppBar
